import styled from 'styled-components';
import {FlexColumnStretchStart, FlexRowCenterStart} from '../widget';
import {Color, INPUT_TYPES} from '../constants';
import React from 'react';
import {Controller} from 'react-hook-form';
import {Select} from 'antd';

const {Option} = Select;

export const Input = (props) => {
  const {field, register, errors, style, control = null} = props;
  if (field.type === INPUT_TYPES.select) {
    return (
      <StyledInput style={style}>
        {field.label && (
          <div className="label">
            {field.rules.required && <div className="required">*</div>}
            <label htmlFor={`filed-${field.name}`}>{field.label}</label>
            <div className="hint">{field.hint}</div>
          </div>
        )}
        <select
          {...register}
          onChange={(e) => {
            register.onChange(e);
            if (field.handleChange) {
              field.handleChange(e);
            }
          }}
          disabled={field.disabled}
          placeholder={field.placeholder}
          defaultValue={field.defaultValue}
          id={`filed-${field.name}`}>
          <option value="" disabled={true}>
            {field.placeholder}
          </option>
          {field.options.map((opt, i) => (
            <option key={i} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
        <div className="error" style={{color: Color.orange}}>
          {errors[field.name]?.type
            ? field.message[errors[field.name].type]
            : null}
        </div>
      </StyledInput>
    );
  } else if (field.type === INPUT_TYPES.multiSelect) {
    return (
      <Controller
        name={field.name}
        defaultValue={field.defaultValue}
        key={field.key}
        control={control}
        rules={field.rules}
        render={({field: {onChange, value}}) => {
          return (
            <StyledInput>
              {field.label && (
                <div className="label">
                  {field.rules.required && <div className="required">*</div>}
                  <label htmlFor={`filed-${field.name}`}>{field.label}</label>
                  <div className="hint">{field.hint}</div>
                </div>
              )}
              <Select
                onChange={(value) => onChange(value)}
                value={value}
                placeholder={field.placeholder}
                id={`filed-${field.name}`}
                mode="multiple"
                style={{borderRadius: 10}}>
                <Option value="請選擇" disabled={true} />
                {field.options.map((opt, i) => (
                  <Option key={i} value={opt.value}>
                    {opt.label}
                  </Option>
                ))}
              </Select>
              <div className="error" style={{color: Color.orange}}>
                {errors[field.name]?.type
                  ? field.message[errors[field.name].type]
                  : null}
              </div>
            </StyledInput>
          );
        }}
      />
    );
  } else if (
    field.type === INPUT_TYPES.text ||
    field.type === INPUT_TYPES.password
  ) {
    return (
      <StyledInput style={style}>
        {field.label && (
          <div className="label">
            {field.rules.required && <div className="required">*</div>}
            <label htmlFor={`filed-${field.name}`}>{field.label}</label>
            <div className="hint">{field.hint}</div>
          </div>
        )}
        <input
          {...register}
          onChange={(e) => {
            register.onChange(e);
            if (field.handleChange) {
              field.handleChange();
            }
          }}
          defaultValue={field.defaultValue}
          placeholder={field.placeholder}
          type={field.type}
          readOnly={field.disabled}
          id={`filed-${field.name}`}
          style={{color: field.disabled ? '#999' : '#000'}}
        />
        <div className="error" style={{color: Color.orange}}>
          {errors[field.name]?.type
            ? field.message[errors[field.name].type]
            : null}
        </div>
      </StyledInput>
    );
  }
};

const StyledInput = styled.div`
  width: 100%;
  ${FlexColumnStretchStart};
  & > .label {
    ${FlexRowCenterStart};
    margin-bottom: 10px;
    & > .hint {
      color: ${Color.orange};
      margin-left: 10px;
      font-size: 10px;
    }
    & > .required {
      color: ${Color.orange};
      font-size: 16px;
    }
  }
  & > input,
  select {
    border: none;
    background-color: ${Color.black_10};
    border-radius: 10px;
    height: 35px;
    padding: 0 10px;
    outline: none;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
  }
  & > .error {
    color: ${Color.orange};
    min-height: 25px;
  }
  .ant-select-selector {
    border-radius: 10px !important;
    background-color: ${Color.black_10} !important;
  }
`;
